import React from 'react'
import './Contact.css'
import Navbar from '../../components/common/navbar/Navbar';
import Alllanding from '../../components/common/alllanding/Alllanding';
import img from '../../utils/images/4.jpg'
import Icons from '../../components/contact/icons/Icons';
import Message from '../../components/contact/message/Message';
import Map from '../../components/contact/map/Map'
import Fotter from '../../components/common/fotter/Fotter'
import Mainfotter from '../../components/common/mainfotter/Mainfotter';
import i3 from '../../utils/images/i3.jpeg'


const Contact = () => {
  const data={
    img:i3,
    heading:'CONTACT '
  };

  return (
    <>
    <Navbar />
    <Alllanding data={data} />
    <Icons/>
    <Message/>
    <Map/>
    <Mainfotter/>
    <Fotter/>
    </> 
  )
}

export default Contact