import React from 'react'
import './Servicessingle.css'
import img from '../../../utils/images/s3.jpg'

const Servicessingle = () => {
  return (
    <>
       <div style={{marginTop:'100px'}} className="container ss-container  ">
           <div className="row">
            <div className="col-12">
                <div style={{backgroundImage:`url(${img})`}} className="ss-img shadow "></div>
            </div>
            <div className="col-12 ss-heading ">
                <h1>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Recusandae quibusdam sint tempora dolorum similique, dolores, mollitia exercitationem vitae maxime ullam consequatur quos odio cumque voluptas iure iste veniam, soluta debitis.
                </h1>
            </div>
            <div className="row ss-p ">
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sequi mollitia doloribus iste alias saepe porro quisquam dolores recusandae repudiandae necessitatibus, fugit, reiciendis iure sit qui doloremque, obcaecati modi voluptatem asperiores?
                </p>
            </div>
           </div>
       </div>
    </>
  )
}

export default Servicessingle