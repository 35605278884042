import React from 'react'
import './Allblogs.css'

import b1 from '../../../utils/images/s1.jpg'
import b2 from '../../../utils/images/s2.jpg'
import b3 from '../../../utils/images/s3.jpg'
import b4 from '../../../utils/images/s4.jpg'
import b5 from '../../../utils/images/s5.jpg'
import b6 from '../../../utils/images/s6.jpg'
import { useNavigate } from 'react-router-dom'

const Allblogs = () => {

    const data = [
        {
            img: b1,
            heading: 'SED RHONCUS VELIT TEMPOR MAURIS',
            typo: 'Sed rhoncus velit tempor mauris lobortis, venenatis tempus sem faucibus. Quisque placerat velit',
        },
        {
            img: b2,
            heading: 'SED RHONCUS VELIT TEMPOR MAURIS',
            typo: 'Sed rhoncus velit tempor mauris lobortis, venenatis tempus sem faucibus. Quisque placerat velit',
        },
        {
            img: b3,
            heading: 'SED RHONCUS VELIT TEMPOR MAURIS',
            typo: 'Sed rhoncus velit tempor mauris lobortis, venenatis tempus sem faucibus. Quisque placerat velit',
        },
        {
            img: b4,
            heading: 'SED RHONCUS VELIT TEMPOR MAURIS',
            typo: 'Sed rhoncus velit tempor mauris lobortis, venenatis tempus sem faucibus. Quisque placerat velit',
        },
        {
            img: b5,
            heading: 'SED RHONCUS VELIT TEMPOR MAURIS',
            typo: 'Sed rhoncus velit tempor mauris lobortis, venenatis tempus sem faucibus. Quisque placerat velit',
        },
        {
            img: b6,
            heading: 'SED RHONCUS VELIT TEMPOR MAURIS',
            typo: 'Sed rhoncus velit tempor mauris lobortis, venenatis tempus sem faucibus. Quisque placerat velit',
        },
    ]

    const navigate = useNavigate();
    const nextblog=()=>{
        navigate('/blogsingle')
    }

    const render = data.map((items) => {
        const { img, heading, typo } = items
        return (
            <>
                <div onClick={nextblog} className="col-sm-12 col-md-12 col-lg-6 col-xl-6  " data-aos="fade-up"   >
                    <div className="blogitems">
                    <div style={{ backgroundImage: `url(${img})` }} className="imgdiv"></div>
                        <h1>{heading}</h1>
                        <p>{typo}</p>
                    </div>
                </div>
            </>
        )
    })

    return (
        <>
            <div className="container blog-container ">
                <div className="row">
                    {
                        render
                    }
                </div>
            </div>
        </>
    )
}

export default Allblogs