import React from 'react'
import './Faq.css';
import Navbar from '../../components/common/navbar/Navbar';
import Alllanding from '../../components/common/alllanding/Alllanding';
import img from '../../utils/images/3.jpg'
import Slider from '../../components/faq/slider/Slider';
import Allfaqs from '../../components/faq/allfaqs/Allfaqs';
import Contact from '../../components/common/Contact/Contact';
import Footer from '../../components/common/fotter/Fotter'
import Mainfotter from '../../components/common/mainfotter/Mainfotter';
import i4 from '../../utils/images/i4.jpeg'

const Faq = () => {
  const data={
    img:i4,
    heading:'FAQ'
  };

  return (
    <>
    <Navbar />
    <Alllanding data={data} />
    <Slider/>
    <Allfaqs/>
    <Contact/>
    <Mainfotter/>
    <Footer/>
    
    </> 
  )
}

export default Faq