import React from 'react'
import './Allservices.css'
import s1 from '../../../utils/images/s1.jpg'
import s2 from '../../../utils/images/s2.jpg'
import s3 from '../../../utils/images/s3.jpg'
import s4 from '../../../utils/images/s4.jpg'
import s5 from '../../../utils/images/s5.jpg'
import s6 from '../../../utils/images/s6.jpg'
import { useNavigate } from 'react-router-dom'

const Allservices = () => {

    const data = [
        {
            img: s1,
            heading: 'ACCIDENTS TOWING',
            typograpy: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sit amet risus orci. Integer pulvinar quam nisl, at maximus velit fringilla ut egestas lacus.',

        },
        {
            img: s2,
            heading: 'WINCH-OUT',
            typograpy: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sit amet risus orci. Integer pulvinar quam nisl, at maximus velit fringilla ut egestas lacus.',

        },
        {
            img: s3,
            heading: 'JUMP STARTING',
            typograpy: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sit amet risus orci. Integer pulvinar quam nisl, at maximus velit fringilla ut egestas lacus.',

        },
        {
            img: s4,
            heading: 'ON-SITE REPAIR',
            typograpy: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sit amet risus orci. Integer pulvinar quam nisl, at maximus velit fringilla ut egestas lacus.',

        },
        {
            img: s5,
            heading: 'TYRE CHANGE',
            typograpy: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sit amet risus orci. Integer pulvinar quam nisl, at maximus velit fringilla ut egestas lacus.',

        },
        {
            img: s6,
            heading: 'STORAGE FACILITY',
            typograpy: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sit amet risus orci. Integer pulvinar quam nisl, at maximus velit fringilla ut egestas lacus.',

        },
    ]

    const navigate = useNavigate()

    const serviceone=()=>{
            navigate('/servicesingle')
    }

    const rend = data.map((items, index) => {
        const { img, heading, typograpy } = items;
        return (
            <>
            {/* <div className="allservices-container"> */}
                <div  className="col-sm-12 col-md-12 col-lg-2 col-xl-2 "></div>
                <div className="col-sm-12 col-md-12 col-lg-8 col-xl-8 ">
                    <div className="row" data-aos="fade-up"   >
                        {index % 2 === 0 ? (
                            <>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 order-2 order-sm-2 order-md-2 order-lg-1 order-xl-1 services-img "  >
                                    <img src={img} alt="" />
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 order-1 order-sm-1 order-md-1 order-lg-2 order-xl-2 allervices-main  ">
                                    <div className="allservices-content">
                                        <div className="allservices-line"></div>
                                        <h1>{heading}</h1>
                                        <p>{typograpy}</p>
                                        <button onClick={serviceone} >KNOW MORE</button>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div onClick={serviceone} className="col-xl-6 col-lg-6 col-md-12 col-sm-12  allervices-main "  >
                                    <div className="allservices-content">
                                    <div className="allservices-line"></div>
                                        <h1>{heading}</h1>
                                        <p>{typograpy}</p>
                                        <button onClick={serviceone}  >KNOW MORE</button>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  services-img  services-img-2 ">
                                    <img src={img} alt="" />
                                </div>
                            </>
                        )}
                    </div>

                </div>
                <div className="col-sm-12 col-md-12 col-lg-2 col-xl-2 "></div>
                {/* </div> */}
            </>
        )
    })

    return (
        <>
            <div className="container-fluid allservices-container ">
                <div className="container">
                    <div className="row">
                        {
                            rend
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Allservices