import React from 'react'
import './Blog.css'
import Navbar from '../../components/common/navbar/Navbar';
import Alllanding from '../../components/common/alllanding/Alllanding';
import img from '../../utils/images/1.jpg'
import Allblogs from '../../components/blog/Allblogs/Allblogs';
import Footer from '../../components/common/fotter/Fotter'
import Orangeoverlay from '../../components/common/orangeoverlay/Orangeoverlay';
import Contact from '../../components/common/Contact/Contact';
import Mainfotter from '../../components/common/mainfotter/Mainfotter';
import i2 from '../../utils/images/i2.jpeg'

const Blog = () => {
    const data = {
        img: i2,
        heading: 'BLOGS'
    };

    return (
        <>
            <Navbar />
            <Alllanding data={data} />
            <Allblogs/>
            <Orangeoverlay/>
            <Contact/>
            <Mainfotter/>
            <Footer/>
        </>
    )
}

export default Blog