import React from 'react'
import './Mainfotter.css'

import { useNavigate } from 'react-router-dom'

const Mainfotter = () => {


    const navigate = useNavigate()

    const navi = (data) => {
        if (data != 'home') {
            navigate(`/${data}`)
        }
    }


    return (
        <>
            {/* <!-- Site footer --> */}
            <footer class="site-footer">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <h6>About</h6>
                            <p class="text-justify">
                                Towing work involves the transportation of vehicles that are unable to move independently due to various reasons, including breakdowns, accidents, or legal issues. Tow truck operators provide essential services to assist stranded motorists and address emergency situations on the road. Here's what towing work typically entails:
                            </p>
                        </div>

                        <div class="col-xs-6 col-md-3">
                            <h6>Services</h6>
                            <ul class="footer-links">
                                <li style={{cursor:'pointer'}} ><a>ACCIDENTS TOWING</a></li>
                                <li style={{cursor:'pointer'}}><a>WINCH-OUT</a></li>
                                <li style={{cursor:'pointer'}}><a>JUMP STARTING</a></li>
                                <li style={{cursor:'pointer'}}><a>ON-SITE REPAIR</a></li>
                                <li style={{cursor:'pointer'}}><a>TYRE CHANGE</a></li>
                                <li style={{cursor:'pointer'}}><a>STORAGE FACILITY</a></li>
                            </ul>
                        </div>

                        <div class="col-xs-6 col-md-3">
                            <h6>Pages</h6>
                            <ul class="footer-links">
                                <li style={{ cursor: 'pointer' }} onClick={() => navi('')}> <a>Home</a> </li>
                                <li style={{ cursor: 'pointer' }} onClick={() => navi('about')}> <a>About</a> </li>
                                <li style={{ cursor: 'pointer' }} onClick={() => navi('contact')}><a>Contact</a></li>
                                <li style={{ cursor: 'pointer' }} onClick={() => navi('services')}><a>Services</a></li>
                                <li style={{ cursor: 'pointer' }} onClick={() => navi('testimonials')}><a>Testimonials</a></li>
                                <li style={{ cursor: 'pointer' }} onClick={() => navi('faq')}><a>Faq</a></li>
                                <li style={{ cursor: 'pointer' }} onClick={() => navi('blog')}><a>Blog</a></li>
                            </ul>
                        </div>
                    </div>
                    <hr />
                </div>
                {/* <div class="container">
                    <div class="row">
                        <div class="col-md-8 col-sm-6 col-xs-12">
                            <p class="copyright-text">Copyright &copy; 2017 All Rights Reserved by
                                <a href="#">Scanfcode</a>.
                            </p>
                        </div>

                        <div class="col-md-4 col-sm-6 col-xs-12">
                            <ul class="social-icons">
                                <li style={{ cursor: 'pointer' }}><a class="facebook" ><i class="fa fa-facebook"></i></a></li>
                                <li style={{ cursor: 'pointer' }}><a class="twitter" ><i class="fa fa-twitter"></i></a></li>
                                <li style={{ cursor: 'pointer' }}><a class="dribbble" ><i class="fa fa-dribbble"></i></a></li>
                                <li style={{ cursor: 'pointer' }}><a class="linkedin" ><i class="fa fa-linkedin"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div> */}
            </footer>
        </>
    )
}

export default Mainfotter