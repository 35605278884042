import React from 'react'

import './Landing.css'

const Landing = () => {
    return (
        <>
            <div className="container landing-container ">
                <div className="landing-overlay"></div>
                <div className="landing-content">
                    <h4>
                        ROADSIDE ASSISTANCE, WHEN YOU NEED IT THE MOST
                    </h4>
                    <h1>
                        THE HIGHEST QUALITY
                    </h1>
                    <h1>
                        <span>
                            TOWING SERVICES
                        </span>
                    </h1>
                    <div className="landing-line"></div>
                </div>

                <div className="landing-bottom" data-aos="fade-left" >
                    <div className='landing-bottom-heading' >
                        24/7 EMERGENCY TOWING
                    </div>
                    <div className='landing-bottom-typo' >
                        +1 646-683-5288
                    </div>
                </div>
            </div>
        </>
    )
}

export default Landing