import React from 'react'
import './Home.css'
import Navbar from '../../components/common/navbar/Navbar'
import Landing from '../../components/home/Landing/Landing'
import About from '../../components/home/About/About'
import Testimonials from '../../components/home/Testimonials/Testimonials'
import Contact from '../../components/common/Contact/Contact'
import Fotter from '../../components/common/fotter/Fotter'
import Orangeoverlay from '../../components/common/orangeoverlay/Orangeoverlay'
import Mainfotter from '../../components/common/mainfotter/Mainfotter'

const Home = () => {
  return (
    <div>
      <Navbar/>
      <Landing/>
      <About/>
      <Testimonials/>
      <Orangeoverlay/>
      <Contact/>
      <Mainfotter/> 
      <Fotter/>
    </div>
  )
}

export default Home