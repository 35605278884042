import React from 'react'
import './Slider.css'

import car1 from '../../../utils/images/car1.png'
import car2 from '../../../utils/images/car2.png'
import car3 from '../../../utils/images/car3.png'
import car4 from '../../../utils/images/car4.png'



import { Carousel } from 'antd';
const contentStyle = {
    margin: 0,
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};


  

const Slider = () => {

    const onChange = (currentSlide) => {
        console.log(currentSlide);
    };

    return (
        <>
            <div className="container faq-con ">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 " data-aos="fade-up"   >
                        <div className="faq-content">
                            <h1>FREQUENTLY ASKED QUESTIONS</h1>
                            <div className="experience-line"></div>
                            <h4>WHY WOULD I NEED TO BE TOWED?</h4>
                            <p>In facilisis, nisl quis convallis mollis, risus mi sollicitudin tortor, sit amet elementum est nulla ut sapien. Nunc orci nulla, mollis et sodales sit amet, condimentum vitae elit. Nam laoreet aliquet tristique.</p>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 " data-aos="fade-down"   >
                        
                        {/* /// */}

                        <Carousel className='sli' afterChange={onChange}>
                            <div className='slider-one-con' >

                                {/* <h3 style={contentStyle}>1</h3> */}
                                <img src={car1} alt="" />
                            </div>
                            <div className='slider-one-con'  >
                                {/* <h3 style={contentStyle}>2</h3> */}
                                <img src={car2} alt="" />
                            </div>
                            <div className='slider-one-con'  >
                                {/* <h3 style={contentStyle}>3</h3> */}
                                <img src={car3} alt="" />
                            </div>
                            <div className='slider-one-con'  >
                                {/* <h3 style={contentStyle}>4</h3> */}
                                <img src={car4} alt="" />
                            </div>
                        </Carousel>
                        {/* /// */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Slider