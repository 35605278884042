import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from './pages/home/Home'
import About from './pages/about/About'
import Contact from './pages/contact/Contact'
import Services from './pages/services/Services'
import Faq from './pages/faq/Faq'
import Testimonials from './pages/testimonials/Testimonials';
import Blog from './pages/blog/Blog';
import BlogSingle from './pages/singleblogpage/Singleblogpage';
import Servicessingle from './pages/singleservicepage/Singleservicepage';
import { useEffect } from 'react';
import AOS from 'aos'; // Import AOS

const App = () => {

  useEffect(() => {
    AOS.init({
      duration: 800, // Animation duration in milliseconds
      offset: 200, // Offset (in px) from the top of the window
    });

  }, [])

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blogsingle" element={<BlogSingle />} />
          <Route path="/servicesingle" element={<Servicessingle />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App