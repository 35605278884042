import React from 'react'
import './Message.css'

const Message = () => {
    return (
        <>
            <div className="container message-container ">
                <div className="message-overlay"></div>
                <div className="message-card" data-aos="fade-up"  >
                    <h1>MESSAGE US</h1>
                    <input placeholder='Name' type="text" />
                    <input placeholder='Email' type="text" />
                    <input placeholder='Phone' type="text" />
                    <textarea placeholder='Message' name="" id="" cols="30" rows="10"></textarea>
                    <button>SEND MESSAGE</button>
                </div>
            </div>
        </>
    )
}

export default Message