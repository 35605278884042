import React from 'react'
import BlogSingle from '../../components/blog/Allblogs/BlogSingle'
import Navbar from '../../components/common/navbar/Navbar'

const Singleblogpage = () => {
    return (
        <>
            <Navbar />
            <BlogSingle />
        </>
    )
}

export default Singleblogpage