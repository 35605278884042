import React, { useEffect , useState } from 'react'
import './Navbar.css'
import { BsFillTelephoneFill } from "react-icons/bs";

import { LiaGripLinesSolid } from "react-icons/lia";

// import { FaGripLines } from "react-icons/fa6";

import { useNavigate } from 'react-router-dom'


const Navbar = () => {

    const navigate = useNavigate()

    const [activehome,setActivehome]=useState('')
    const [activeabout,setActiveabout]=useState('')
    const [activeservices,setActiveservices]=useState('')
    const [activetest,setActivetest]=useState('')
    const [activefaq,setActivefaq]=useState('')
    const [activeblog,setActiveblog]=useState('')
    const [activecontact,setActivecontact]=useState('')

    useEffect(()=>{

    },[])

    const navi = (data) => {
        if(data != 'home'){
            navigate(`/${data}`)
        }
        else{
            navigate(`/`)
        }
        // if(data=='home'){
        //     setActivehome('active');
        //     setActiveabout('');
        //     setActiveservices('');
        //     setActivetest('');
        //     setActivefaq('');
        //     setActiveblog('');
        //     setActivecontact('')
        //     navigate(`/`)
            
        // }
        // if(data=='about'){
        //     setActivehome('');
        //     setActiveabout('active');
        //     setActiveservices('');
        //     setActivetest('');
        //     setActivefaq('');
        //     setActiveblog('');
        //     setActivecontact('')
        // }
        // if(data=='services'){
        //     setActivehome('');
        //     setActiveabout('');
        //     setActiveservices('active');
        //     setActivetest('');
        //     setActivefaq('');
        //     setActiveblog('');
        //     setActivecontact('')
        // }
        // if(data=='testimonials'){
        //     setActivehome('');
        //     setActiveabout('');
        //     setActiveservices('');
        //     setActivetest('active');
        //     setActivefaq('');
        //     setActiveblog('');
        //     setActivecontact('')
        // }
        // if(data=='faq'){
        //     setActivehome('');
        //     setActiveabout('');
        //     setActiveservices('');
        //     setActivetest('');
        //     setActivefaq('active');
        //     setActiveblog('');
        //     setActivecontact('')
        // }
        // if(data=='blog'){
        //     setActivehome('');
        //     setActiveabout('');
        //     setActiveservices('');
        //     setActivetest('');
        //     setActivefaq('');
        //     setActiveblog('active');
        //     setActivecontact('')
        // }
        // if(data=='contact'){
        //     setActivehome('');
        //     setActiveabout('');
        //     setActiveservices('');
        //     setActivetest('');
        //     setActivefaq('');
        //     setActiveblog('');
        //     setActivecontact('active')
        // }

    }


    return (
        <>

            <div className="container-fluid nav-toy shadow-lg ">

                {/* <div className="container"> */}

                <div className="container ">
                    <div className="row">
                        <div className="col-12  nav-head  ">
                            <h1>TOW NOW</h1>
                            <div className='open-time-main' >
                                <div className="open-time-raduis">
                                    <h2>!</h2>
                                </div>
                                <div className="open-time-inner">
                                    <div className='nav-text' >OPEN TIME</div>
                                    <div className='nav-text' >Mon-Sun | 24 Hours </div>
                                </div>
                            </div>
                            {/* ///// */}
                            <div className='open-time-main' >
                                <div className="open-time-raduis">
                                    < BsFillTelephoneFill />
                                </div>
                                <div className="open-time-inner">
                                    <div className='nav-text' >EMERGENCY CALL</div>
                                    <div className='nav-text' >+1 646-683-5288 </div>
                                </div>
                            </div>
                            <div className="free">
                                GET FREE ESTIMATES
                            </div>
                        </div>
                    </div>
                </div>



                {/* //////////////// */}

                <div className="container-fluid nav-con ">
                    <div className="container">
                        <nav class="navbar navbar-expand-lg ">
                            <div class="container-fluid nav-b ">
                                {/* <a class="navbar-brand" href="#">Navbar</a> */}
                                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                    <span class="navbar-toggler-icon">
                                        {/* <FaGripLines/> */}
                                        <LiaGripLinesSolid />
                                    </span>
                                </button>
                                <div class="collapse navbar-collapse" id="navbarNav">
                                    <ul class="navbar-nav">
                                        <li class="nav-item">
                                            <a style={{ cursor: 'pointer' }} onClick={() => navi('home')} class={`nav-link ${activehome} `} aria-current="page">Home</a>
                                        </li>
                                        <li class="nav-item">
                                            <a style={{ cursor: 'pointer' }} onClick={() => navi('about')} class={`nav-link ${activeabout}`}  >About</a>
                                        </li>
                                        <li class="nav-item">
                                            <a style={{ cursor: 'pointer' }} onClick={() => navi('services')} class={`nav-link ${activeservices} `}  >Services</a>
                                        </li>
                                        <li class="nav-item">
                                            <a style={{ cursor: 'pointer' }} onClick={() => navi('testimonials')} class={`nav-link ${activetest} `}  >Testimonials</a>
                                        </li>
                                        <li class="nav-item">
                                            <a style={{ cursor: 'pointer' }} onClick={() => navi('faq')} class={`nav-link ${activefaq} `}  >Faq</a>
                                        </li>
                                        <li class="nav-item">
                                            <a style={{ cursor: 'pointer' }} onClick={() => navi('blog')} class={`nav-link ${activeblog} `}  >Blog</a>
                                        </li>
                                        <li class="nav-item">
                                            <a style={{ cursor: 'pointer' }} onClick={() => navi('contact')} class={`nav-link ${activecontact} `}  >Contact</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </nav>

                    </div>
                </div>



            </div>
            {/* </div> */}


        </>
    )
}

export default Navbar