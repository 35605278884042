import React from 'react'
import './Mission.css';

const Mission = () => {
    return (
        <>
            <div className="container mission-container ">
                <div className="mission-overlay"></div>
                <div className="mission-content" data-aos="fade-up" >
                    <h1>OUR VISION & MISSION</h1>
                    <div className="experience-line"></div>
                    <div  className='d-flex justify-content-start align-items-center ' style={{width:'100%'}} >
                    <span > Delivering Reliable Towing Solutions with Unwavering Integrity, Compassion, and a Fierce Commitment to Restoring Your Peace of Mind on Every Road You Travel.</span>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-start align-items-center ">
                        <p>
                        our vision is to be the industry leader in towing services, setting new standards of excellence and innovation. We envision a future where every customer experiences the highest level of service, efficiency, and safety, ensuring that their trust in us is well-placed. We aspire to continually raise the bar in the towing industry, providing not just a service but a promise of reliability, quality, and customer satisfaction
                        </p>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-start align-items-center  " style={{marginTop:'-21px'}} >
                        <p>
                        to provide top-notch towing solutions to our valued customers while upholding the principles of integrity and care. We are dedicated to being the reliable lifeline our customers need in times of vehicle distress. Our mission is to ensure that every interaction with is marked by professionalism, transparency, and a genuine commitment to solving our customers' problems swiftly and effectively. 
                        </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Mission