import React from 'react'
import './Cards.css'

const Cards = () => {
    return (
        <>
            <div className="container cards-container ">
                <div className="container card-container-inner ">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 ">
                            <div class="card shadow-lg " >
                                <div class="card-body d-flex justify-content-center align-items-center flex-column " data-aos="fade-left" >
                                    <h5>MINIMUM COST</h5>
                                    <h1 className='card-heading' >LOW-END</h1>
                                    <div style={{ marginTop: '-0px' }} className="experience-line"></div>
                                    <h1 className='card-price' >$50</h1>
                                    <p>FOR THE FIRST 10 MILES</p>
                                    <ul className=' d-flex justify-content-around align-items-center flex-column ' >
                                        <li> Refill gas</li>
                                        <li>Jumpstart a battery</li>
                                        <li>Unlock the car door</li>
                                        <li>Transport a broken car</li>
                                        <li>Tire change</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 " data-aos="fade-up" >
                            <div class="card shadow-lg card-mid " >
                                <div class="card-body d-flex justify-content-center align-items-center flex-column ">
                                    <h5>MINIMUM COST</h5>
                                    <h1 className='card-mid-heading' >LOW-END</h1>
                                    <div style={{ marginTop: '-0px', backgroundColor: 'black' }} className="experience-line"></div>
                                    <h1 className='card-mid-price' >$50</h1>
                                    <p>FOR THE FIRST 10 MILES</p>
                                    <ul className=' d-flex justify-content-around align-items-center flex-column ' >
                                        <li> Refill gas</li>
                                        <li>Jumpstart a battery</li>
                                        <li>Unlock the car door</li>
                                        <li>Transport a broken car</li>
                                        <li>Tire change</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 ">
                            <div class="card shadow-lg " >
                                <div class="card-body d-flex justify-content-center align-items-center flex-column " data-aos="fade-right" >
                                    <h5>MINIMUM COST</h5>
                                    <h1 className='card-heading' >LOW-END</h1>
                                    <div style={{ marginTop: '-0px' }} className="experience-line"></div>
                                    <h1 className='card-price' >$50</h1>
                                    <p>FOR THE FIRST 10 MILES</p>
                                    <ul className=' d-flex justify-content-around align-items-center flex-column ' >
                                        <li> Refill gas</li>
                                        <li>Jumpstart a battery</li>
                                        <li>Unlock the car door</li>
                                        <li>Transport a broken car</li>
                                        <li>Tire change</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cards