import React from 'react'
import Servicessingle from '../../components/services/servicesingle/Servicessingle'
import Navbar from '../../components/common/navbar/Navbar'
import Alllanding from '../../components/common/alllanding/Alllanding'

import img from '../../utils/images/1.jpg'
import Mainfotter from '../../components/common/mainfotter/Mainfotter'
import Footer  from '../../components/common/fotter/Fotter'

const Singleservicepage = () => {

  const data = {
    img: img,
    heading: 'Service'
  };

  return (
    <div>
      <Navbar />
      <Alllanding data={data} />
      <Servicessingle />
      <Mainfotter/>
      <Footer/>
    </div>
  )
}

export default Singleservicepage