import React from 'react';
import { BsFacebook , BsTwitter  } from "react-icons/bs";

import { GrInstagram } from "react-icons/gr";

const BlogSingle = () => {
  return (
    <>
       <div className="container" style={{marginTop:'70px'}} >
        <div className="row">
            <div className="col-xl-7 col-lg-7 col-sm-12 col-md-12 ">
              <div style={{ backgroundImage: 'url("https://images.pexels.com/photos/18101408/pexels-photo-18101408/free-photo-of-beautiful-old-town-of-florence.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load")' }} className="bs-img"></div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-5  bs-description ">
                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corrupti magni, consequuntur maiores quos at officiis cumque necessitatibus assumenda ea ratione sequi deleniti, tenetur veritatis voluptatem fugiat nesciunt. Voluptates, officia doloribus?</p>
                 <div className="sb-author-main">
                    <div className="sb-author"></div>
                    <div className="sb-author-name">Author</div>
                 </div>
                    <div className="sb-icon">
                           <BsFacebook style={{marginLeft:'8px'}} /><BsTwitter style={{marginLeft:'8px'}} /><GrInstagram style={{marginLeft:'8px'}} />
                    </div>
            </div>
        </div>

         <div className="row bs-content ">
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate repudiandae, tempore placeat ab qui, impedit reprehenderit, perspiciatis saepe quos assumenda sapiente. Accusantium necessitatibus repellat quam neque laboriosam officia explicabo cupiditate.
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore excepturi voluptatem, iusto maiores, explicabo mollitia ullam officiis porro pariatur eum, eveniet quis sed vitae consequuntur? A debitis autem tempora doloremque!
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nulla, quo. Inventore praesentium perspiciatis eos corporis error maiores fugit aspernatur, ipsa repellat a magni libero illo dignissimos ea quos dolores minus.
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium, quaerat perspiciatis quas voluptatum aspernatur inventore a qui quibusdam adipisci! Labore sint ea voluptate totam pariatur consequuntur nam iste natus enim.
            </p>
         </div>

       </div>
    </>
  );
}

export default BlogSingle;
