import React from 'react';
import './Map.css';

const Map = () => {
    return (
        <div className="container map-con">
            <div className="map">
                <div className="mapouter">
                    <iframe
                        className="gmap_iframe"
                        title="Google Map"
                        frameBorder="0"
                        scrolling="no"
                        marginHeight="0"
                        marginWidth="0"
                        src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=2880 Broadway, New York&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                    ></iframe>
                </div>
            </div>
        </div>
    );
};

export default Map;
