import React from 'react'
import './Services.css';
import Navbar from '../../components/common/navbar/Navbar';
import Alllanding from '../../components/common/alllanding/Alllanding';
import img from '../../utils/images/1.jpg'
import Allservices from '../../components/services/allservices/Allservices';
import Contact from '../../components/common/Contact/Contact'
import Footer from '../../components/common/fotter/Fotter'
import Servicecard from '../../components/services/servicecard/Servicecard';
import Mainfotter from '../../components/common/mainfotter/Mainfotter';

const Services = () => {

  const data={
    img:img,
    heading:'SERVICES'
  };

  return (
    <>
    <div style={{overflow:'hidden'}} >
    <Navbar />
    <Alllanding data={data} />
    <Servicecard/>
    <Allservices/>
    <Contact/>
    <Mainfotter/>
    <Footer/>
    </div>
    </> 
  )
}

export default Services