import React from 'react'
import './Servicecard.css'

const Servicecard = () => {
    return (
        <>
            <div className="container service-card-con " >
                <div className="row d-flex justify-content-center align-items-center flex-column ">
                    <h1>OUR SERVICES</h1>
                    <div className="experience-line"></div>
                </div>
                <div className="row service-card-paralex "  >
                    <div className="service-card-overlay"></div>
                    <div className="sc" data-aos="fade-up"   >
                        <h3>
                            MEMBERSHIP
                        </h3>
                        <h1>
                            ROADSIDE ASSISTANCE
                        </h1>
                        <div className='sc-img' >
                            <div className="sc-img-overlay"></div>
                        </div>
                        <div className="row" >
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 ul ">
                                <li>24/7 emergency towing</li>
                                <li>Emergency roadside assistance</li>
                                <li>Fuel delivery services</li>
                                <li>Tire changing</li>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 ul ">
                                <li>
                                    Car battery jump start
                                </li>
                                <li>
                                    Replacement battery
                                </li>
                                <li>
                                    Tire changing
                                </li>
                                <li>
                                    Car Locksmith Solutions
                                </li>
                            </div>
                        </div>
                        <button>JOIN NOW</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Servicecard