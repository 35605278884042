import React from 'react'
import './Icons.css'

import { AiOutlineMail , AiFillPhone } from "react-icons/ai";
import { MdLocationOn  } from "react-icons/md";

const Icons = () => {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12 d-flex justify-content-center align-items-center contact-heading flex-column "   >
                        <h1>CONTACT INFO</h1>
                        <div className="experience-line"></div>
                    </div>
                </div>
                <div className="container contact-container ">
                    <div className="row">
                        <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12  " data-aos="fade-up"  >
                            <div className="contact-box">
                                <h6><AiOutlineMail style={{color:'black',fontSize:'25px'}} /></h6>
                                <h1>EMAIL</h1>
                                <p>
                                afepowertowing@gmail.com
                                </p>
                            </div>
                        </div>
                        {/* // */}
                        {/* <div className="col-lg-4 col-xl-4 col-md-12 col-sm-12  ">
                            <div className="contact-box">
                                <h6><AiFillPhone style={{color:'black',fontSize:'25px'}} /></h6>
                                <h1>ADDRESS</h1>
                                <p>
                                123 Fifth Avenue, New York, NY 10160
                                </p>
                            </div>
                        </div> */}
                        {/* // */}
                        <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12  " data-aos="fade-down"  >
                            <div className="contact-box">
                                <h6><MdLocationOn style={{color:'black',fontSize:'25px'}} /></h6>
                                <h1>PHONE</h1>
                                <p>
                                +1 646-683-5288
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Icons