import React from 'react'
import './Alllanding.css'

const Alllanding = ({ data }) => {
  const { img, heading } = data
  return (
    <>
      <div className="container-fluid alllanding-con ">
        <div style={{ backgroundImage: `url(${img})` }} className="landing-container">
          <div className="alllandingoverlay"></div>
          <div className="alllandingcontent">{heading}</div>
          <div className="alllanding-bottom" data-aos="fade-left" >
            <h2>
              24/7 EMERGENCY TOWING
            </h2>
            <h1>
              +1 646-683-5288
            </h1>
          </div>
        </div>
      </div>
    </>
  )
}

export default Alllanding