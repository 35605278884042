import React from 'react'
import './Fotter.css'

const fotter = () => {
    return (
        <>

            <div className="container-fluid container-fotter-fluid ">
                <div className="container">
                    <div className="row">
                        <div className="col-12 container-fotter ">
                            Copyright © 2023 TOW NOW DIGIUNCTION
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default fotter