import React from 'react'
import './Allfaqs.css'
// import { Collapse } from 'antd';

const Allfaqs = () => {

    const data = [
        {
            question: 'What types of vehicles can you tow?',
            answer: 'We can tow a wide range of vehicles, including cars, motorcycles, trucks, RVs, boats, and more. Our fleet is equipped to handle various sizes and types of vehicles.'
        },
        {
            question: 'Is your towing service available 24/7?',
            answer: 'Yes, we provide 24/7 towing services, ensuring that you can reach us whenever you need assistance, day or night.'
        },
        {
            question: 'How quickly can you respond to a towing request?',
            answer: 'We aim to arrive at your location within 30 minutes or less of your call, providing swift assistance in your time of need.'
        },
        {
            question: 'Do you provide long-distance towing services?',
            answer: 'Yes, we offer long-distance towing services, making it easy to transport your vehicle over significant distances safely and efficiently.'
        },
    ]

    const data2 = [
        {
            question: 'What payment methods do you accept?',
            answer: 'We accept various payment methods, including cash, credit cards, and insurance claims, for your convenience.'
        },
        {
            question: 'Is your towing service licensed and insured?',
            answer: 'Yes, we are a fully licensed and insured towing company, ensuring that you and your vehicle are protected during the towing process.'
        },
        {
            question: 'Do you offer roadside assistance in addition to towing?',
            answer: 'Yes, we provide a range of roadside assistance services, including jump-starts, tire changes, fuel delivery, lockout service, and more.'
        },
        {
            question: 'How do I request your towing service?',
            answer: `You can reach us by calling our dedicated phone line at [Your Contact Number] or using our online contact form to request assistance. We'll be there to help you promptly.`
        },
    ]

    const render = data.map((items, index) => {
        const { question, answer } = items;
        const itemId = `collapse_${index}`; // Generate a unique ID for each item
        return (
            <div key={index} class="accordion-item" data-aos="fade-left"   >
                <h2 class="accordion-header" id={`heading_${index}`}>
                    <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#${itemId}`}
                        aria-expanded="false"
                        aria-controls={itemId}
                    >
                        {question}
                    </button>
                </h2>
                <div id={itemId} class="accordion-collapse collapse" aria-labelledby={`heading_${index}`} data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        {answer}
                    </div>
                </div>
            </div>
        );
    });
    

    const render2 = data2.map((items, index) => {
        const { question, answer } = items;
        const itemId = `collapse_${index+5}`; // Generate a unique ID for each item
        return (
            <div key={index+5} class="accordion-item" data-aos="fade-right"   >
                <h2 class="accordion-header" id={`heading_${index+5}`}>
                    <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#${itemId}`}
                        aria-expanded="false"
                        aria-controls={itemId}
                    >
                        {question}
                    </button>
                </h2>
                <div id={itemId} class="accordion-collapse collapse" aria-labelledby={`heading_${index+5}`} data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        {answer}
                    </div>
                </div>
            </div>
        );
    });

    return (
        <>
            <div className="container faq-paralex-con ">
                <div className="faq-orange"></div>
                <div className="container faq-paralex-con-inner ">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            {/* //// */}
                            <div class="accordion" id="accordionExample">
                                {
                                      render
                                }

                            </div>
                            {/* //// */}
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            {/* //// */}
                            <div class="accordion" id="accordionExample">
                                {
                                    render2
                                }
                            </div>
                            {/* //// */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Allfaqs