import React from 'react'
import './Testimonials.css'
import Navbar from '../../components/common/navbar/Navbar';
import Alllanding from '../../components/common/alllanding/Alllanding';
import img from '../../utils/images/1.jpg'
import AllTestimonials from '../../components/testimonials/AllTestimonials';
import Number from '../../components/testimonials/number/Number';
import Orangeoverlay from '../../components/common/orangeoverlay/Orangeoverlay'
import Contact from '../../components/common/Contact/Contact'
import Footer from '../../components/common/fotter/Fotter'
import Mainfotter from '../../components/common/mainfotter/Mainfotter';
import i1 from '../../utils/images/i1.jpeg'

const Testimonials = () => {
    const data={
        img:i1,
        heading:'TESTIMONIALS'
      };
    
      return (
        <>
        <Navbar />
        <Alllanding data={data} />
        <AllTestimonials />
        <Number/>
        <Orangeoverlay/>
        <Contact/>
        <Mainfotter/>
        <Footer/>
        </> 
      )
}

export default Testimonials