import React, { useState } from 'react'
import './About.css'
import Navbar from '../../components/common/navbar/Navbar';
import Alllanding from '../../components/common/alllanding/Alllanding';
import Experience from '../../components/about/experience/Experience';
import img from '../../utils/images/2.jpg'
import Cards from '../../components/about/cards/Cards';
import Mission from '../../components/about/mission/Mission';
import Footer from '../../components/common/fotter/Fotter'
import Contact from '../../components/common/Contact/Contact';
import Orangeoverlay from '../../components/common/orangeoverlay/Orangeoverlay';
import Mainfotter from '../../components/common/mainfotter/Mainfotter';
import i1 from '../../utils/images/i1.jpeg'

const About = () => {
  const data={
    img:i1,
    heading:'ABOUT'
  };

  const [m,setM]=useState(true)

  // const [addclass,setAddclass]=useState(true)

  return (
    <>
    <Navbar />
    <Alllanding data={data} />
    <Experience/>
    <Orangeoverlay/>
    <Cards/>
    <Mission/>
    <Contact m={m} />
    <Mainfotter/>
    <Footer/>
    </> 
  )
}

export default About