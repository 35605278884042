import React from 'react'
import './Number.css'

import s1 from '../../../utils/images/under.jpg'

// import s1 from 'https://a.catand.us/townow/wp-content/uploads/sites/14/elementor/thumbs/car-mechanics-working-and-maintaining-car-ZWSVXFB-1-p8r4z7k4kobj5fzqd62zyhg15nwof3gqrkub7sk3ns.jpg'

const Number = () => {
    return (
        <>
            <div className="container number-con ">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 d-flex justify-content-center align-items-center " data-aos="fade-left"   >
                            <div style={{ backgroundImage: `url(${s1})` }} className="number-img-card"></div>
                            {/* <div style={{ backgroundImage: `url(${})` }} className="number-img-card"></div> */}
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12  ">
                            <div className="row d-flex justify-content-center align-items-center flex-column number-heading ">
                                <h1>NUMBERS SPEAK</h1>
                                <div className="experience-line"></div>
                                <div className="row">
                                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 d-flex justify-content-center align-items-center  ">
                                        <div className="number-box">
                                            <h1>22</h1>
                                            <p>Years Experience</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4  d-flex justify-content-center align-items-center  ">
                                        <div className="number-box">
                                            <h1>24/7</h1>
                                            <p>Response</p>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4  d-flex justify-content-center align-items-center  ">
                                        <div className="number-box">
                                            <h1>100+</h1>
                                            <p>Mechanics</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Number