import React from 'react'
import './Orangeoverlay.css'

const Orangeoverlay = () => {
    return (
        <>
            <div className="contact-paralex-container">
                <div className="contact-paralex-overlay"></div>
                <div className="contact-paralex-content">
                    <h2>
                        REFER A FRIEND IN DISTRESS AND HE WILL GET
                    </h2>
                    <h1>

                        PRICE REDUCED BY 15%
                    </h1>
                </div>
            </div>
        </>
    )
}

export default Orangeoverlay