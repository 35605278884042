import React from 'react'
import './Experience.css'

const Experience = () => {
    return (
        <>
            <div className="container container-experience ">
                <div class="row d-flex justify-content-center align-items-center flex-column">
                    <h1 className='exp-h' >EXPERIENCE</h1>
                    <div className="experience-line"></div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex justify-content-start align-items-start flex-column exp-content " data-aos="fade-right" >
                        <h1 style={{color:'white'}} > MORE THAN 50 YEARS OF EXPERIENCE</h1>
                        <p >
                        With over five decades of dedicated service in the towing industry, has cultivated a wealth of experience and expertise. Our journey began more than 50 years ago, and during this time, we've seen it all and handled every imaginable towing situation. Our extensive history has allowed us to refine our skills, invest in cutting-edge technology, and develop innovative solutions to ensure that you receive the most reliable assistance available. You can trust that our deep-rooted knowledge in the towing field equips us to handle any situation, from simple roadside assistance to complex towing and recovery operations.
                        </p>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex justify-content-start align-items-start flex-column exp-content " data-aos="fade-left" >
                        <h1 style={{color:'white'}} >FRIENDLY AND PROFESSIONAL SERVICE</h1>
                        <p >
                         We don't just prioritize experience; we also understand the importance of providing a friendly and professional service. Our commitment to customer satisfaction goes beyond the job itself. Our team is not only highly skilled but also dedicated to making your towing experience as stress-free as possible. We take pride in our courteous and approachable staff who are always ready to assist you with a smile. Whether you find yourself in a challenging roadside situation or simply need a tow, you can expect our team to treat you with respect, provide clear communication, and ensure a seamless and professional experience from start to finish.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Experience