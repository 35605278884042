import React from 'react'
import './Testimonials.css'

const Testimonials = () => {

    const data = [
        {
            img: 'https://images.pexels.com/photos/9789893/pexels-photo-9789893.jpeg?auto=compress&cs=tinysrgb&w=600',
            name: 'CHRISTINE LAWSON',
            typography: `I was in a real bind when my car broke down on the freeway during rush hour. And they were there in less than 30 minutes, just as they promised. The service was fast and affordable, and the driver was incredibly professional. I couldn't have asked for a better experience. Thanks for saving the day!`,
        },
        {
            img: 'https://images.pexels.com/photos/4890259/pexels-photo-4890259.jpeg?auto=compress&cs=tinysrgb&w=600',
            name: 'CAROLYN CARPENTER',
            typography: `Finding a towing service that doesn't break the bank can be a real challenge, but I hit the jackpot with . Their rates are truly affordable, and the best part is that they're available 24/7. I've had to call them late at night and on weekends, and they're always quick to respond. Their team is friendly and knowledgeable, and I highly recommend their services to anyone in need of a reliable towing company`,
        },
        
    ]


  const render = data.map((items) => {
        const { img, name, typography } = items
        return (
            <>
                <div className="row testi-one " data-aos="fade-up" >
                    <div className="col-sm-12 col-md-12 col-lg-3 col-xl-3 testi-raduis-main ">
                        <div style={{ backgroundImage: `url(${img})` }} className="testi-raduis"></div>
                        <h1>{name}</h1>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-9 col-xl-9 testi-raduis-typo">
                        <p>
                            <div>"</div>
                            {typography}
                            <div style={{ color: 'rgb(237,78,9)',marginLeft:'90%' }} >"</div>
                        </p>
                    </div>
                </div>
            </>
        )
    })



    return (
        <>
            <div className="container-fluid test-main ">
                <div className="container container-testimonials ">
                    <div class="row testi-heading  ">
                        <h1>TESTIMONIALS</h1>
                        <span className='testi-line' ></span>
                    </div>
                     {
                        render
                     }
                </div>
            </div>
        </>
    )
}

export default Testimonials