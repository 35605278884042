import React from 'react'
import './About.css'
import { BsClock , BsFillTelephoneFill , BsCurrencyDollar } from "react-icons/bs";

const About = () => {
    return (
        <>
            <div className="container-fluid about-container ">
                <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-1 col-sm-1"></div>
                    <div className="col-xl-8 col-lg-8 col-md-11 col-sm-11 ">
                        <div className="about-content" data-aos="fade-up" >
                            <h1>About</h1>
                            <div className="about-line"></div>
                            <h3>FAST AND INEXPENSIVE TOWING SERVICES</h3>
                            <p>
                            we understand that vehicle breakdowns can happen at any time and are often a source of stress and inconvenience. That's why we're proud to offer fast and inexpensive towing services that will get you back on the road in no time without breaking the bank.
                            </p>
                            <button>KNOW MORE</button>
                        </div>
                    </div>
                </div>
                {/* //// */}
                <div className="container about-grid ">
                    <div className="row">
                        <div className="col-lg-8 ">
                            <div className="about-box-main" data-aos="fade-right" >
                                <div className="about-box ab1"></div>
                                <div className="about-box-abs  "></div>
                                <div className="about-box-content">ACCIDENTS TOWING</div>
                            </div>
                        </div>
                        <div className="col-lg-4  ">
                            <div className="about-box-main" data-aos="fade-left" >
                                <div className="about-box ab2 "></div>
                                <div className="about-box-abs-org"></div>
                                <div className="about-box-content">TYRE CHANGE</div>

                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 ">
                            <div className="about-box-main"  data-aos="fade-right" >
                                <div className="about-box ab3 "></div>
                                <div className="about-box-abs-org"></div>
                                <div className="about-box-content">JUMP STARTING</div>
                            </div>
                        </div>
                        <div className="col-lg-8 ">
                            <div className="about-box-main"  data-aos="fade-left" >
                                <div className="about-box ab4 "></div>
                                <div className="about-box-abs"></div>
                                <div className="about-box-content">ON-SITE REPAIR</div>

                            </div>

                        </div>
                    </div>
                    <div className="row" data-aos="fade-up" >
                        <div className="col-lg-4  "  >
                            <div className=" about-last-box about-time">
                                <div className="about-last-box-content">
                                    <BsClock style={{ fontSize: '40px' }} />
                                    <h4> LESS THAN 30 MIN ARRIVAL</h4>
                                    <p>we promise to be at your location within 30 minutes or less from the moment you call us. </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 ">
                            <div className=" about-last-box about-service">
                                <div className="about-last-box-content">
                                    <BsFillTelephoneFill style={{ fontSize: '40px' }} />
                                    <h4> 24/7 SERVICE​ CALL(800) 910-626-852</h4>
                                    <p>24/7 Towing Services: Help Just a Phone Call Away </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 ">
                            <div className=" about-last-box about-price">
                                <div className="about-last-box-content">
                                    <BsCurrencyDollar style={{ fontSize: '40px' }} />
                                    <h4>AFFORDABLE PRICES ALWAYS</h4>
                                    <p> Affordable 24/7 Towing Services: Quality Assistance Without Breaking the Bank </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default About