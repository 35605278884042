import React from 'react'
import './Contact.css'

import { AiFillMessage , AiFillPhone } from "react-icons/ai";
import { MdLocationOn } from "react-icons/md";

const Contact = ({m}) => {
    return (
        <>
            <div className="container-fluid contact-container">

                {/* ////// */}
                <div className="container contact-last ">
                    <div className="container">
                        <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6  " data-aos="fade-up" >
                        <div  className={` ${m?'m':'contact-card'} `}>
                                <h1>GET DISCOUNT UPDATES</h1>
                                <input type="text" placeholder='Name' />
                                <input type="text" placeholder='Email' />
                                <button>Subscribe Now</button>
                            </div>
                        </div>
                        {/* // */}
                        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
                            <div className={`${m?'ml':'contact-last-content'}`}>
                                <div className="contact-last-content-line"></div>
                                <h6> <span><AiFillMessage/></span>afepowertowing@gmail.com</h6>
                                {/* <h6> <span><AiFillPhone/></span> 123 Fifth Avenue, New York, NY 10160</h6> */}
                                <h6> <span><MdLocationOn/></span> +1 646-683-5288</h6>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact