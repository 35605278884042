import React from 'react'
import './AllTestimonials.css'

import b1 from '../../utils/images/1.jpg'
import b2 from '../../utils/images/s2.jpg'
import b3 from '../../utils/images/s3.jpg'
import b4 from '../../utils/images/s4.jpg'
import b5 from '../../utils/images/s5.jpg'
import b6 from '../../utils/images/s6.jpg'

const AllTestimonials = () => {

  const data = [
    {
      img: 'https://images.pexels.com/photos/9789893/pexels-photo-9789893.jpeg?auto=compress&cs=tinysrgb&w=600',
      name: 'CHRISTINE LAWSON',
      typography: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sequi possimus, ',
    },
    {
      img: 'https://images.pexels.com/photos/4890259/pexels-photo-4890259.jpeg?auto=compress&cs=tinysrgb&w=600',
      name: 'CAROLYN CARPENTER',
      typography: 'Nullam commodo consequat augue, id cursus nisl. Duis lobortis sodales velit, quis maximus urna commodo ultricies.',
    },

    {
      img: 'https://images.pexels.com/photos/9789893/pexels-photo-9789893.jpeg?auto=compress&cs=tinysrgb&w=600',
      name: 'CHRISTINE LAWSON',
      typography: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sequi possimus, ',
    },
    {
      img: 'https://images.pexels.com/photos/4890259/pexels-photo-4890259.jpeg?auto=compress&cs=tinysrgb&w=600',
      name: 'CAROLYN CARPENTER',
      typography: 'Nullam commodo consequat augue, id cursus nisl. Duis lobortis sodales velit, quis maximus urna commodo ultricies.',
    },

    {
      img: 'https://images.pexels.com/photos/9789893/pexels-photo-9789893.jpeg?auto=compress&cs=tinysrgb&w=600',
      name: 'CHRISTINE LAWSON',
      typography: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sequi possimus, ',
    },
    {
      img: 'https://images.pexels.com/photos/4890259/pexels-photo-4890259.jpeg?auto=compress&cs=tinysrgb&w=600',
      name: 'CAROLYN CARPENTER',
      typography: 'Nullam commodo consequat augue, id cursus nisl. Duis lobortis sodales velit, quis maximus urna commodo ultricies.',
    },

  
  ]

  const render = data.map((items) => {
    const { img, name, typography } = items
    return (
      <>
        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 alltestimonials-on  " data-aos="fade-up"   >
          <div className="row testi-one ">
            <div className="col-sm-12 col-md-12 col-lg-3 col-xl-3 testi-raduis-main ">
              <div style={{ backgroundImage: `url(${img})`,height:'80px',width:'80px' }} className="testi-raduis"></div>
              <h1>{name}</h1>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-9 col-xl-9 testi-raduis-typo">
              <p  >
                <div>"</div>
                {typography}
                <div  style={{ color: 'rgb(237,78,9)', marginLeft: '90%' }} >"</div>
              </p>
            </div>
          </div>

        </div>
      </>
    )
  })

  return (
    <>
      <div className="container alltestimonials-container ">
        <div className="row">
          {
            render
          }
        </div>
      </div>
    </>
  )
}

export default AllTestimonials